<template>
  <div class="home-page">
    <!-- 顶部组件 -->
    <!-- <search-form :form="form"
                 @select="select"
                 @selectAddress="selectAddress"></search-form> -->

    <div class="ym-deploy"
         style="width: 100%">
      <div class="panel-title">合约部署面板</div>
      <el-card style="margin-top: 20px"
               shadow="never">
        <el-form :model="contractForm"
                 :rules="rules"
                 ref="contractForm"
                 label-position="top"
                 size="small"
                 class="contractForm-body">

          <el-form-item label="分片名"
                        prop="shard">
            <el-select @change="handleShard"
                       v-model="contractForm.shard">
              <el-option v-for="item in shardList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="address-intro" label="节点地址"
                        prop="address">
            <el-select @change="handleAddress"
                       v-model="contractForm.address">
              <el-option v-for="item in addressList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="hashAlgorithm-intro" label="使用的加密算法"
                        prop="hashAlgorithm">
            <el-select v-model="contractForm.hashAlgorithm"
                       clearable
                       placeholder="请选择">
              <el-option label="sha3"
                         value="sha3"></el-option>
              <el-option label="sm3"
                         value="sm3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="accountAlgorithm-intro" prop="accountAlgorithm">
            <div class="label-div"
                 slot="label">
              <div>生成账户的算法</div>
              <el-button class="build-btn"
                         icon="el-icon-user-solid"
                         type="primary"
                         size="small"
                         :disabled="!contractForm.accountAlgorithm"
                         v-loading="buildLoading"
                         @click="handleBuild()">生成</el-button>
            </div>
            <el-select v-model="contractForm.accountAlgorithm"
                       clearable
                       placeholder="请选择">
              <el-option label="rsa"
                         value="rsa"></el-option>
              <el-option label="sm2"
                         value="sm2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="pubKey">
            <div class="label-div"
                 slot="label">
              <div>账户地址</div>
              <el-button class="build-btn"
                         icon="el-icon-document-copy"
                         type="text"
                         size="small"
                         @click="copy($event, contractForm.pubKey)">复制</el-button>
            </div>
            <el-input type="textarea"
                      readonly
                      rows="4"
                      v-model="contractForm.pubKey"></el-input>
          </el-form-item>
          <el-form-item prop="priKey">
            <div class="label-div"
                 slot="label">
              <div>账户密码</div>
              <el-button class="build-btn"
                         icon="el-icon-document-copy"
                         type="text"
                         size="small"
                         @click="copy($event, contractForm.priKey)">复制</el-button>
            </div>
            <el-input type="textarea"
                      readonly
                      rows="4"
                      v-model="contractForm.priKey"></el-input>
          </el-form-item>
          <el-form-item class="language-intro" label="使用的开发语言"
                        prop="language">
            <el-select v-model="contractForm.language"
                       clearable
                       placeholder="请选择">
              <el-option label="JavaScript"
                         value="js"></el-option>
              <el-option label="Python"
                         value="python"></el-option>
              <el-option label="Ruby"
                         value="ruby"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合约名称"
                        prop="remark">
            <el-input type="textarea"
                      placeholder="请输入"
                      maxlength="30"
                      rows="3"
                      show-word-limit
                      v-model="contractForm.remark"></el-input>
          </el-form-item>
          <div class="column-div">
            <el-button class="deploy-btn" type="primary"
                       v-loading="loading"
                       @click="submitForm('contractForm')"
                       icon="el-icon-upload">部署合约</el-button>
            <el-button class="reset-btn" style="width:100%;margin-left: 0px;margin-top: 20px;"
                       @click="resetForm()"
                       icon="el-icon-refresh-left">重置</el-button>
          </div>
        </el-form>
        <!-- 代码面板 -->
        <div id="code"
             class="code-panel">
          <codemirror class="codemirror" ref="cm"
                      v-model="contractForm.code"
                      :options="cmOptions"
                      @input="inputChange"> </codemirror>
        </div>
        <!-- 调用面板 -->
        <el-form :model="callForm"
                 ref="callForm"
                 label-position="top"
                 size="small"
                 class="contractForm-body">
          <el-form-item prop="contractID">
            <div class="label-div"
                 slot="label">
              <div>合约ID</div>
              <el-button class="build-btn"
                         icon="el-icon-document-copy"
                         type="text"
                         size="small"
                         @click="copy($event, callForm.contractID)">复制</el-button>
            </div>
            <el-input type="textarea"
                      readonly
                      rows="4"
                      v-model="callForm.contractID"></el-input>
          </el-form-item>
          <el-form-item prop="info"
                        label="合约信息">
            <el-input type="textarea"
                      class="info-div"
                      readonly
                      rows="41"
                      v-model="info">
            </el-input>
          </el-form-item>
        </el-form>
      </el-card>
    </div>

  </div>
</template>

<script>
import Clipboard from 'clipboard'
// import SearchForm from './component/SearchForm.vue'
// 引入组件
import { codemirror } from 'vue-codemirror'
// 引入高亮样式
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/base16-light.css'
// 引入语言
import 'codemirror/mode/javascript/javascript.js'
// 搜索功能
// find：Ctrl-F (PC), Cmd-F (Mac)
// findNext：Ctrl-G (PC), Cmd-G (Mac)
// findPrev：Shift-Ctrl-G (PC), Shift-Cmd-G (Mac)
// replace：Shift-Ctrl-F (PC), Cmd-Alt-F (Mac)
// replaceAll：Shift-Ctrl-R (PC), Shift-Cmd-Alt-F (Mac)
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/dialog/dialog'
import 'codemirror/addon/search/searchcursor'
import 'codemirror/addon/search/search'
import 'codemirror/addon/search/jump-to-line'
import 'codemirror/addon/search/matchesonscrollbar'
import 'codemirror/addon/search/match-highlighter'

// 代码提示功能 具体语言可以从 codemirror/addon/hint/ 下引入多个
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/javascript-hint'

// 高亮行功能
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/selection/selection-pointer'

// 调整scrollbar样式功能
import 'codemirror/addon/scroll/simplescrollbars.css'
import 'codemirror/addon/scroll/simplescrollbars'

// 自动括号匹配功能
import 'codemirror/addon/edit/matchbrackets'

// 显示自动刷新
import 'codemirror/addon/display/autorefresh'

// 多语言支持？
import 'codemirror/addon/mode/overlay'
import 'codemirror/addon/mode/multiplex'

// 代码段折叠功能
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter'
import 'codemirror/addon/fold/foldgutter.css'

import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/comment-fold'
import 'codemirror/addon/fold/xml-fold.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'

// merge功能
import 'codemirror/addon/merge/merge.css'
import 'codemirror/addon/merge/merge'
// google DiffMatchPatch
import DiffMatchPatch from 'diff-match-patch'
import {
  getShardName,
  setShardName
  // setNetworkType,
  // getNetworkType
} from '@/utils/localStorage.js'
// DiffMatchPatch config with global
window.diff_match_patch = DiffMatchPatch
window.DIFF_DELETE = -1
window.DIFF_INSERT = 1
window.DIFF_EQUAL = 0
export default {
  components: {
    // SearchForm,
    codemirror
  },
  data() {
    return {
      loading: false,
      copyLoading: false,
      buildLoading: false,
      callForm: {
        contractID: ''
      },
      info: '',
      // address: '', // 节点地址
      shardList: [],
      addressList: [],
      contractForm: {
        shard: '', // 分片名
        address: '', // 节点地址
        hashAlgorithm: '',
        accountAlgorithm: '',
        pubKey: '',
        priKey: '',
        language: '',
        peerHost: '',
        peerPort: '',
        code: '',
        remark: ''
      },
      rules: {
        shard: [{ required: true, message: '请选择分片名', trigger: 'change' }],
        address: [
          { required: true, message: '请选择节点地址', trigger: 'change' }
        ],
        hashAlgorithm: [
          { required: true, message: '请选择加密算法', trigger: 'change' }
        ],
        accountAlgorithm: [
          {
            required: true,
            message: '请选择生成账户的算法',
            trigger: 'change'
          }
        ],
        pubKey: [
          { required: true, message: '请生成账户地址', trigger: 'change' }
        ],
        priKey: [
          { required: true, message: '请生成账户密码', trigger: 'change' }
        ],
        language: [
          {
            required: true,
            message: '请选择合约的开发语言',
            trigger: 'change'
          }
        ]
      },
      cmOptions: {
        mode: 'text/javascript',
        theme: 'base16-light',
        line: true, // 显示函数
        lineNumbers: true, // 显示行号
        foldGutter: true,
        lineWrapping: true, // 代码折叠
        gutters: [
          'CodeMirror-linenumbers',
          'CodeMirror-foldgutter',
          'CodeMirror-lint-markers'
        ],
        // tab宽度
        tabSize: 4,
        // 代码提示功能
        hintOptions: {
          // 避免由于提示列表只有一个提示信息时，自动填充
          completeSingle: true,
          // 不同的语言支持从配置中读取自定义配置 sql语言允许配置表和字段信息，用于代码提示
          hint: this.handleShowHint
        },
        // 高亮行功能
        styleActiveLine: true,
        // 调整scrollbar样式功能
        scrollbarStyle: 'overlay',
        // 自动括号匹配功能
        matchBrackets: true
      }
    }
  },
  created() {
    // this.form.state = getNetworkType()
    this.getAliases()
  },
  mounted() {
    this.$refs.cm.codemirror.setSize('auto', 792 + 'px')
    // 新手指引
    this.$nextTick(() => {
      this.$guide.myIntroJs(this.$route.name)
    })
  },
  methods: {
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = []
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.shardList = array
        this.contractForm.shard = getShardName() || array[0].value
        this.getAddressList()
      }
    },
    async getAddressList() {
      let params = {
        // networkType: this.form.state,
        peerName: this.contractForm.shard,
        keepLive: true
      }
      let { code, data } = await this.$api.home.getNodes(params)
      if (code === 200) {
        var array = []
        for (let item of data) {
          var obj = {}
          obj.value = item.networkIP + ':' + item.networkPort
          obj.label = item.networkIP + ':' + item.networkPort
          array.push(obj)
        }
        this.addressList = array
      } else {
        this.$message.error('请求节点列表失败！')
      }
    },
    inputChange(content) {
      this.$nextTick(() => {
        console.log('code:' + this.contractForm.code)
        console.log('content:' + content)
      })
    },
    // 切换节点地址事件
    handleAddress(value) {
      const array = value.split(':')
      this.contractForm.peerHost = array[0]
      // 后端已经对端口默认+1了
      this.contractForm.peerPort = JSON.parse(array[1])
      // this.contractForm.peerPort = JSON.parse(array[1]) + 1
      // console.log(this.contractForm.peerPort, this.contractForm.peerHost)
    },
    // 切换网络类型
    // selectState(value) {
    //   if (value !== '') {
    //     setNetworkType(value)
    //     console.log('存储网络类型:', value)
    //   }
    // },
    // 存储分片名
    handleShard(value) {
      this.addressList = []
      this.getAddressList()
      setShardName(value)
      // console.log('存储分片名:', value)
    },
    // 一键复制
    copy(e, text) {
      if (this.copyLoading) {
        return
      }
      console.log(e, text)
      const clipboard = new Clipboard(e.target, { text: () => text })
      this.copyLoading = true
      clipboard.on('success', (e) => {
        this.$message({ type: 'success', message: '复制成功' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({ type: 'waning', message: '该浏览器不支持自动复制' })
        // 释放内存
        clipboard.off('error')
        clipboard.off('success')
        clipboard.destroy()
      })
      clipboard.onClick(e)
      setTimeout(() => {
        this.copyLoading = false
      }, 1000)
    },
    // 生成账户
    async handleBuild() {
      if (this.buildLoading) {
        return
      }
      let body = {
        accountAlgorithm: this.contractForm.accountAlgorithm
      }
      this.buildLoading = true
      const { data } = await this.$api.middleWare.generate(body)
      // console.log(JSON.parse(data))
      let content = JSON.parse(data)
      this.contractForm.pubKey = content.publicKey
      this.contractForm.priKey = content.privateKey
      setTimeout(() => {
        this.buildLoading = false
      }, 1000)
    },
    // 提交
    submitForm(formName) {
      if (this.loading) {
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.contractForm.code) {
            this.$message.error('请编写合约代码')
            return
          } else if (!this.contractForm.remark) {
            this.contractForm.remark = '部署合约'
          }
          this.loading = true
          this.$api.middleWare.deploy(this.contractForm).then((res) => {
            console.log(res.data)
            let parse = JSON.parse(res.data)
            // let obj = {}
            // obj['sendAddress'] = parse.sendAddress
            // obj['verifyAddress'] = parse.verifyAddress
            // obj['hash'] = parse.hash
            this.info = res.data
            this.callForm.contractID = parse.hash
          })
          setTimeout(() => {
            this.loading = false
          }, 1000)
        }
      })
    },
    resetForm() {
      // 重置表单并清除校验结果
      this.$refs.contractForm.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.panel-title{
  width: 160px;
}
.ym-deploy {
  min-width: 540px;
  border: $--border-color-base;
  border-radius: $--border-radius-base;
  background-color: $--bg-color-table;
  overflow: hidden;
  color: $--color-text-primary;
  padding: 20px;
  /deep/.el-card__body {
    padding: 20px;
    display: flex;
    flex-direction: row;
    // background: rgb(250, 250, 250);
  }
  .contractForm-body {
    min-width: 300px;
    width: 26%;
    .column-div {
      display: flex;
      flex-direction: column;
    }
    .ym-deploy-header-left {
      float: left;
    }
    /deep/.el-select {
      width: 100%;
    }
    /deep/.el-form-item__label {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    .label-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // background: seashell;
      width: 100%;
      // line-height: 32px;
    }
    .build-btn {
      // position: relative;
      // right: 0;
      // top: 40px;
      // line-height: 20px;
    }
  }
  .code-panel {
    width: 46%;
    min-width: 440px;
    // min-height: 900px;
    margin: 10px 1%;
  }
  /deep/.CodeMirror {
    font-family: monospace;
    direction: ltr;
    min-height: 979px !important;
  }

  .info-div {
    // padding: 10px;
    // height: 600px;
    // overflow: scroll;
    // border: 2px dotted rgb(182, 182, 182);
    // border-radius: 0.3rem;
    // background: seashell;
    // color: rgb(153, 187, 125);
  }
  .info-div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
  }
}
</style>
